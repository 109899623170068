<template>
      <Frame>

        <template v-slot:title>
            Dashboard
        </template>

        <template v-slot:content>
            <v-container style="height:100vh;" class="vertical-align-center text-center" fluid>
            <h1 style="font-size:24px">Dashboard.</h1>
            </v-container>
        </template>

      </Frame>

</template>

<script>

import Frame from '@/ui/main/Frame.vue'

export default {
    name: 'Dashboard',
    components: {
        Frame,
    }, 
}
</script>
